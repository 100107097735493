import React, { useState } from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const ContactPage = ({
  data: {
    site
  },
}) => {

  const handleClick = (e) => {
    e.preventDefault();
    postData({ name: w3lName, email: w3lSender, subject: w3lSubject, message: w3lMessage });
  }
  const [w3lName, setName] = useState("");
  const [w3lSender, setSender] = useState("");
  const [w3lSubject, setSubject] = useState("");
  const [w3lMessage, setMessage] = useState("");


  const handleChange = (e) => {
    switch (e.target.name) {
      case "w3lName":
        setName(e.currentTarget.value);
        break;
      case "w3lSender":
        setSender(e.currentTarget.value);
        break;
      case "w3lSubject":
        setSubject(e.currentTarget.value);
        break;
      case "w3lMessage":
        setMessage(e.currentTarget.value);
        break;
      default:
        break;
    }
  };

  const postData = (data) => {
    console.log(data)
    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest()

    // get a callback when the server responds
    xhr.addEventListener('load', () => {
      // update the state of the component with the result here
      console.log(xhr.responseText)
    })
    // open the request with the verb and the url
    xhr.open('POST', 'https://us-central1-nivu-me.cloudfunctions.net/nivu-mail')
    // send the request
    xhr.send(JSON.stringify(data))
  }


  return (
    <Layout>
      <Helmet>
        <title>Contact — {site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <div className="two-grids -contact">
        <div className="post-thumbnail" style={{ backgroundImage: `url('/assets/alexander-andrews-HgUDpaGPTEA-unsplash.jpg')`, marginBottom: 0 }}>
          <h1 className="post-title">Get in Touch</h1>
          <p>Let me help you kick start your next project &rarr;</p>
        </div>
        <div>
          <form className="form-container" action="https://sendmail.w3layouts.com/SubmitContactForm" method="post">
            <div>
              <label htmlFor="w3lName">Name</label>
              <input type="text" value={w3lName} onChange={handleChange} name="w3lName" id="w3lName" />
            </div>
            <div>
              <label htmlFor="w3lSender">Email</label>
              <input type="email" value={w3lSender} onChange={handleChange} name="w3lSender" id="w3lSender" />
            </div>
            <div>
              <label htmlFor="w3lSubject">Subject</label>
              <input type="text" value={w3lSubject} onChange={handleChange} name="w3lSubject" id="w3lSubject" />
            </div>
            <div>
              <label htmlFor="w3lMessage">Message</label>
              <textarea value={w3lMessage} onChange={handleChange} name="w3lMessage" id="w3lMessage"></textarea>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <input type="submit" className="button -primary" style={{ marginRight: 0 }} onClick={handleClick} />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}
export default ContactPage
export const pageQuery = graphql`
  query ContactPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`